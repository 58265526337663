import { axios, API_ROUTES } from "@/config/index.js";

export default {
  async getAll() {
    let result = await axios.get(`${API_ROUTES.enterprise.get}`);

    return result;
  },

  async find(uuid) {
    let result = await axios.get(`${API_ROUTES.enterprise.find}/${uuid}`);

    return result;
  },

  async getAllEnterpriseByAdmin(uuid) {
    let result = await axios.get(`${API_ROUTES.enterpriseAdmin.get}/${uuid}`);

    return result;
  },

  async getAllPayments(uuid) {
    let result = await axios.get(`${API_ROUTES.historyPlan.get}/${uuid}`);

    return result;
  },

  async save(id, data) {
    let result = await axios.post(`${API_ROUTES.enterprise.save}`, data);

    return result;
  },

  async saveImage(data) {
    let result = await axios.post(`${API_ROUTES.image.save}`, data);

    return result;
  },

  async update(id, data) {
    let result = await axios.put(`${API_ROUTES.enterprise.update}/${id}`, data);

    return result;
  },

  async delete(id) {
    let result = await axios.delete(`${API_ROUTES.enterprise.delete}/${id}`);

    return result;
  },
}